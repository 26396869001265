import "./App.css";

import React, { useReducer } from "react";
import { appReducer, initialState } from "./redux/reducer";
import Router from "./Router";
import { Helmet, HelmetProvider } from "react-helmet-async";

const helmetContext = {};

function App() {
  const [state, dispatch] = useReducer(appReducer, initialState);
  // TODO: explore wrapping Router with useContext and/or useCallback
  // for passing state and dispatch
  return (
    <HelmetProvider context={helmetContext}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Peg Hop</title>
        <link rel="canonical" href="https://www.peghop.com/" />
      </Helmet>
      <Router state={state} dispatch={dispatch} />
    </HelmetProvider>
  );
}

export default App;
