/* eslint-disable eqeqeq */
import map from "lodash/map";
import flatten from "lodash/flatten";
import flatMapDeep from "lodash/flatMapDeep";
import { rows, width, height, middle } from "../config";
import pegUtils from "./pegUtils";

const boardUtils = {
  buildBoard: (level = rows) => {
    const rowsArray = [...Array(level).keys()];
    const newBoard = rowsArray.reduce((acc, row, idx) => {
      if (idx === 0) {
        acc[idx] = boardUtils.buildNode(idx, idx, level);
        return acc;
      }
      const innerRowsArray = [...Array(row + 1).keys()];
      const compoundRows = innerRowsArray.reduce(
        (innerAcc, innerRow, innerIdx) => {
          const node = boardUtils.buildNode(idx, innerIdx, level);
          innerAcc = { ...innerAcc, [innerIdx]: node };
          return innerAcc;
        },
        {}
      );
      acc = { ...acc, [idx]: compoundRows };
      return acc;
    }, {});
    const updatedBoard = boardUtils.removePiece(newBoard, level);
    return updatedBoard;
  },
  getCoords: (m, n, level) => {
    const xInterval = width / level;
    const yInterval = height / level;

    const calcHeight = () => (m + 1) * yInterval - yInterval / 2;
    const calcWidth = () => middle + (n - m / 2) * xInterval;

    return { cx: calcWidth(), cy: calcHeight() };
  },
  buildNode: (row, column, level) => {
    const coords = boardUtils.getCoords(row, column, level);
    return { checked: true, row, column, coords };
  },
  flattenBoard: (board) => {
    return map(board, (level) => {
      const { row } = level;
      if (row === 0) return level;
      return flatMapDeep(level);
    });
  },
  buildBoardState: (board) => {
    if (!board) return false;
    return flatten(boardUtils.flattenBoard(board));
  },
  buildAvailableMoves: (board) => {
    if (!Array.isArray(board)) return false;
    return board.reduce((acc, item, idx) => {
      const { row, column, checked } = item;
      acc[`${row}${column}`] = !checked;
      return acc;
    }, {});
  },
  filterPiece: (id, board) => {
    if (!id) return false;
    const [pegRow, pegColumn] = id;
    return board.filter((piece) => {
      if (piece.column == pegColumn && piece.row == pegRow) return piece;
      return false;
    });
  },
  computeScore: (moves = 0, startTime, score = 0) => {
    if (!moves) return score;
    // const now = new Date();
    // const start = startTime.getTime && startTime.getTime();
    // const nowToMs = now.getTime();
    // const timeDiff = ((nowToMs - start) / 1000) % 2;
    // const deductTime = moves * 10 < timeDiff;
    // const timeDeduction = deductTime ? timeDiff * 10 : 0;

    return moves * 42 + score;
    // return moves * 1000 - timeDeduction + score;
  },
  movePiece: (validPeg, emptySpace, board) => {
    const [m, n] = emptySpace;
    const [x, y] = validPeg;
    const pieceToDelete = pegUtils.medianPiece(validPeg, emptySpace);
    const [q, r] = pieceToDelete;
    const updatedBoardState = { ...board };
    const results = map(updatedBoardState, (piece, idx) => {
      const rowMatch = piece.row == m;
      const oldPegRowMatch = piece.row == x;
      const columnMatch = piece.column == n;
      const oldPegColumnMatch = piece.column == y;

      const deleteRowMatch = piece.row == q;
      const deleteColumnMatch = piece.column == r;

      if (rowMatch && columnMatch) {
        piece.checked = true;
      }
      if (oldPegRowMatch && oldPegColumnMatch) {
        piece.checked = false;
      }
      if (deleteRowMatch && deleteColumnMatch) {
        piece.checked = false;
      }
      return piece;
    });

    return results;
  },
  filterCheckedPieces: (board) => {
    if (!Array.isArray(board)) return false;
    if (board.length === 0) return false;
    return board.filter((piece) => piece.checked);
  },
  removePiece: (board, level) => {
    let updatedBoard;
    if (level === 4) {
      // if level is 4 then remove piece from second row
      updatedBoard = board;
      updatedBoard[1][0].checked = false;
      return updatedBoard;
    }

    if (!board || Object.keys(board).length === 0) return board;
    updatedBoard = board; // TODO randomize missing pieces
    updatedBoard[0].checked = false;
    return updatedBoard;
  },
};

export default boardUtils;
