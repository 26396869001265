import { makeStyles } from "@material-ui/core";

const useDefaultStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    position: "absolute",
    padding: "2.5vw",
    top: 0,
    width: "95vw",
    height: "100vh",
  },
  button: {
    padding: "1rem",
    background: "rgba(0,0,0,0.2)",
    color: "rgba(255,255,255,0.8)",
    fontSize: "1.5rem",
    "&:hover": {
      color: "rgba(0,0,0,0.8)",
    },
  },
  link: {
    textDecoration: "none",
  },
  title: {
    margin: "2rem",
    color: "#252525",
  },
});

export default useDefaultStyles;
