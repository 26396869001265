import { Typography, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  wrapper: {
    color: "#252525",
    padding: "2rem 0 ",
    justifySelf: "center",
    textAlign: "center",
  },
  background: {
    background: "rgba(0,0,0,0.2)",
    color: "#f3f3f3",
    padding: "2rem",
    borderRadius: "5px",
  },
});

const About = ({ dispatch, location, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Typography variant="h2">Rules </Typography>
      <Typography variant="body1" className={classes.background}>
        Jump all pegs until only one remains. A valid move is to jump a peg
        orthogonally over an adjacent peg into a hole two positions away. Jump
        each peg by clicking on the one which you'd like to move. If a move in
        invalid you will see a notification indicating as such. The jumped peg
        will automatically be removed. If you can eliminate all pegs except one
        then you win!
      </Typography>
    </div>
  );
};

export default About;
