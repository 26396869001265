import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
// eslint-disable-next-line no-unused-vars

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    display: "grid",
    height: "100vh",
    width: "100vw",
    overflow: "hidden",
    color: "#fefefe",
    alignContent: "center",
    position: "absolute",
    gridTemplateAreas: "'header' 'game' 'footer '",
    gridTemplateRows: "0.6fr 1.4fr 0.6fr",
    gridTemplateColumns: "1fr",
    [theme.breakpoints.down("md")]: {
      // Max width 1280px
      gridTemplateAreas: "'header' 'game' 'footer'",
      gridTemplateRows: "0.1fr 0.1fr  0.6fr 0.1fr 0.1fr",
      gridTemplateColumns: "1fr",
    },
  },
  background0: {
    backgroundColor: "#e7ffff",
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%23d4fefe' points='800 100 0 200 0 800 1600 800 1600 200'/%3E%3Cpolygon fill='%23c1fcfd' points='800 200 0 400 0 800 1600 800 1600 400'/%3E%3Cpolygon fill='%23affafb' points='800 300 0 600 0 800 1600 800 1600 600'/%3E%3Cpolygon fill='%239ef7f9' points='1600 800 800 400 0 800'/%3E%3Cpolygon fill='%238df3f6' points='1280 800 800 500 320 800'/%3E%3Cpolygon fill='%237deef2' points='533.3 800 1066.7 800 800 600'/%3E%3Cpolygon fill='%236de9ee' points='684.1 800 914.3 800 800 700'/%3E%3C/g%3E%3C/svg%3E")`,
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  background1: {
    backgroundColor: "#ff3fe2",
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%2361ccba' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%233955aa' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%2359c5b7' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%230068b0' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%2351beb4' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%230075a6' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%234ab6b1' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%23007e90' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%2343afae' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%23008476' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%233da8aa' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%233b8860' points='943 900 1210 900 971 687'/%3E%3C/svg%3E")`,
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  background2: {
    backgroundColor: "#9d39aa",
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%239d39aa'/%3E%3Cstop offset='1' stop-color='%234fd'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23f2fcff' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23f2fcff' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%23f2fcff' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23f2fcff' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E")`,
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));
const Wrapper = ({ children, background = 1 }) => {
  const classes = useStyles();

  const backgroundIndex = `background${background}`;
  return (
    <div
      className={clsx([classes.root, "app", classes[backgroundIndex]])}
      id="appWrapper"
    >
      {children}
    </div>
  );
};

export default Wrapper;
