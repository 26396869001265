import { isEmpty, reject } from "lodash";

const pegUtils = {
  getId: (event) => {
    if (!event) return false;
    const {
      srcElement: { id = "" },
      target: { targetId = "" },
    } = event;
    if (!id && !targetId) return false;

    return id || targetId;
  },
  sliceId: (id) => {
    if (!id) return false;
    // removes last two digits from peg
    const idLength = 2;

    return id.slice(`-${idLength}`);
  },
  getPegId: (event) => {
    const id = pegUtils.getId(event);
    const pegId = pegUtils.sliceId(id);
    return pegId;
  },
  computePossibleMoves: (id, level) => {
    if (!id || typeof id !== "string") return false;
    // 6 total scenarios to check
    let possibleMoves = [];
    const [row, column] = id;
    if (!row || !column) return false;
    const x = parseInt(row);
    const y = parseInt(column);
    const rowMinusTwo = x - 2;
    const columnMinusTwo = y - 2;
    const rowPlusTwo = x + 2;
    const columnPlusTwo = y + 2;

    // top left
    if (rowMinusTwo >= 0 && columnMinusTwo >= 0) {
      possibleMoves.push(`${rowMinusTwo}${columnMinusTwo}`);
    }
    // top right
    if (rowMinusTwo >= 0 && columnMinusTwo <= x) {
      possibleMoves.push(`${rowMinusTwo}${y}`);
    }
    //left
    if (columnMinusTwo >= 0) {
      possibleMoves.push(`${x}${columnMinusTwo}`);
    }
    //right
    if (columnPlusTwo <= x) {
      possibleMoves.push(`${x}${columnPlusTwo}`);
    }
    //bottomleft
    if (rowPlusTwo <= level - 1) {
      possibleMoves.push(`${rowPlusTwo}${y}`);
    }
    //bottomright
    if (rowPlusTwo <= level - 1 && columnPlusTwo <= level - 1) {
      possibleMoves.push(`${rowPlusTwo}${columnPlusTwo}`);
    }
    return { [id]: possibleMoves };
  },
  areMovesAvailable: (pegId, availableMoves, level) => {
    const possibleMoves = pegUtils.computePossibleMoves(pegId, level); // tkaes last two characters off id. ex id=Peg32 // pegId = 32

    const checkMoves = (moves) => {
      const results = moves.map((move) => {
        const freespace = availableMoves[move];
        const deleting = pegUtils.progressiveDelete(
          move,
          pegId,
          availableMoves
        );
        return freespace && deleting && move;
      });

      const filteredResults = pegUtils.cleanup(results);
      const returnObj = { [pegId]: filteredResults };
      return returnObj;
    };

    const updatedAvailableMoves =
      possibleMoves && checkMoves(possibleMoves[pegId]);

    return updatedAvailableMoves;
  },
  medianPiece: (old = false, updated = false) => {
    if (!old || !updated) return false;

    const [a, b] = old; // 2, 2 => 1, 1    // 22 => 2 1
    const [k, l] = updated; // 0, 0        // 20 (2 - 2) / 2
    const updatedRow = Math.max(a, k) - Math.abs(a - k) / 2;
    const updatedColumn = Math.max(b, l) - Math.abs((b - l) / 2);
    return `${updatedRow}${updatedColumn}`;
  },
  checkAvailableMoves: (pegs, availableMoves, level) => {
    if (Array.isArray(!pegs) || Array.isArray(!availableMoves)) return false;
    return pegUtils.cleanup(
      pegs.map((peg) => {
        const { column, row } = peg;
        const id = `${row}${column}`;

        const results = pegUtils.areMovesAvailable(id, availableMoves, level);
        return results[id].length ? { [id]: results } : null;
      })
    );
  },

  progressiveDelete: (id, pegId, freeMovesAvailable) => {
    const pieceToDelete = pegUtils.medianPiece(id, pegId);
    const progressiveDelete = !freeMovesAvailable[pieceToDelete];
    return progressiveDelete;
  },
  cleanup: (arr) => reject(arr, isEmpty),
  getRandomNumber: (limit = 3) => {
    return Math.floor(Math.random() * limit);
  },
};

export default pegUtils;
