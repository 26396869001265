import React from "react";
import { makeStyles } from "@material-ui/core";
// import logo from "./../assets/logo.png"; // TODO: inspect other logos
import logo from "./../assets/logo_transparent.png";

const useStyles = makeStyles({
  logoRow: {
    left: 0,
    top: "2rem",
    width: "100vw",
    position: "absolute",
    height: "4rem",
    display: "flex",
    justifyContent: "center",
  },
});

const PegHopLogo = () => {
  const classes = useStyles();

  return (
    <div className={classes.logoRow}>
      <img src={logo} alt="pegHopLogo" />
    </div>
  );
};

export default PegHopLogo;
