import React from "react";
import Wrapper from "../../components/Wrapper";

class CustomErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <Wrapper>
          <p>Ooops, we ran into an error</p>
          <a
            className="App-link Error-reposition-hack"
            href="https://peghop.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Somethings not right here...
          </a>
        </Wrapper>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
export default CustomErrorBoundary;
