export const navigation = {
  home: null,
  board: null,
  init: true,
  about: null,
};

// CONSTANTS
export const UPDATE_BOARD = "UPDATE_BOARD";
export const HOME = "HOME";
export const RESTART = "RESTART";
export const ABOUT = "ABOUT";
export const INIT = "INIT";
export const BOARD = "BOARD";
export const INCREASE_LEVEL = "INCREASE_LEVEL";
