import { rows } from "../config";
import boardUtils from "../utils/boardUtils";
import {
  navigation,
  INIT,
  HOME,
  RESTART,
  ABOUT,
  UPDATE_BOARD,
  BOARD,
  INCREASE_LEVEL,
} from "./constants";

const { buildBoardState, buildBoard, buildAvailableMoves } = boardUtils;

const freshBoard = buildBoardState(buildBoard());
const availableMoves = buildAvailableMoves(freshBoard) || [];

const game = {
  board: freshBoard,
  availableMoves,
  moveHistory: [],
  score: 0,
  startTime: new Date(),
  count: 0,
  level: rows,
};

export const initialState = {
  navigation,
  game,
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT: {
      return {
        ...state,
        ...navigation,
        navigation: action.payload,
      };
    }
    case HOME: {
      return {
        ...state,
        ...navigation,
        navigation: action.payload,
      };
    }
    case RESTART: {
      const newGame = buildBoardState(buildBoard());
      const cleanState = {
        ...state,
        game: {
          ...state.game,
          board: newGame,
          availableMoves: buildAvailableMoves(newGame),
          count: 0,
          score: 0,
          level: rows,
        },
      };
      return cleanState;
    }
    case BOARD: {
      return {
        ...state,
        ...navigation,
        navigation: { board: true, init: false },
      };
    }
    case ABOUT: {
      return {
        ...state,
        ...navigation,
        navigation: { about: true, init: false },
      };
    }
    case UPDATE_BOARD: {
      const { board, count, availableMoves, score } = action;
      return {
        ...state,
        game: {
          ...state.game,
          board,
          count,
          availableMoves,
          score,
        },
      };
    }
    case INCREASE_LEVEL: {
      const { level, score } = action;
      const nextLevel = level + 1;
      const newGame = buildBoardState(buildBoard(nextLevel));
      const newState = {
        ...state,
        game: {
          ...state.game,
          board: newGame,
          availableMoves: buildAvailableMoves(newGame),
          count: 0,
          level: nextLevel,
          score,
        },
      };
      return newState;
    }
    default:
      return { ...state };
  }
};
