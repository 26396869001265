import React from "react";
import Wrapper from "../Wrapper";
import PegHopLogo from "../PegHopLogo";

const Loader = () => {
  return (
    <Wrapper>
      <PegHopLogo />
    </Wrapper>
  );
};

export default Loader;
