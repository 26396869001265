import React from "react";
import { Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Wrapper from "../../components/Wrapper";
import useDefaultStyles from "../../styles/useDefaultStyles";
import About from "../About";
import PegHopLogo from "../../components/PegHopLogo";

const Home = () => {
  const classes = useDefaultStyles();

  return (
    <>
      <Wrapper>
        <div className={classes.root}>
          <PegHopLogo />

          <About />
          <Link to="/" className={classes.link}>
            <Button className={classes.button} variant="contained">
              New Game
            </Button>
          </Link>
        </div>
      </Wrapper>
    </>
  );
};

export default Home;
