import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import CustomErrorBoundary from "./containers/Error";
import Home from "./containers/Home"; // priority load
import Loader from "./components/Loader"; // priority load

// If you want a load screen. START HERE
const Board = lazy(() => import("./containers/Board"));
// const NoMatch = lazy(() => import("./containers/NoMatch"));

function Router({ state, dispatch }) {
  return (
    <BrowserRouter>
      <CustomErrorBoundary>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path="/">
              <Board dispatch={dispatch} state={state} />
            </Route>
            <Route path="/game">
              <Board dispatch={dispatch} state={state} />
            </Route>
            <Route path="/home">
              <Home dispatch={dispatch} state={state} />
            </Route>
            <Route>
              <Board dispatch={dispatch} state={state} />
              {/* <NoMatch dispatch={dispatch} state={state} /> 
              TODO: is this the best UX?*/}
            </Route>
          </Switch>
        </Suspense>
      </CustomErrorBoundary>
    </BrowserRouter>
  );
}

export default Router;
